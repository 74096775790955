import { Form, Input } from "antd";
import {
  ProForm,
  ProFormText,
  ProFormSelect,
} from "@ant-design/pro-components";

const IndividualClientInfo = ({ disabled }) => {
  const requiredRule = {
    required: true,
  };

  return (
    <>
      <ProForm.Group>
        <ProFormText
          name="companyName"
          label="Наименование компании"
          placeholder="Наименование компании"
          rules={[requiredRule]}
          disabled={disabled}
        />
        <ProFormText
          name="fullNameDirector"
          label="Фамилия и инициалы директора"
          placeholder="Фамилия и инициалы директора"
          rules={[requiredRule]}
          disabled={disabled}
        />
        <ProFormSelect
          placeholder="На каком основании является директором"
          label="На каком основании является директором"
          name="onWhatBasisDirector"
          rules={[requiredRule]}
          disabled={disabled}
          options={[
            {
              value: "BY_STATUE",
              label: "на основании устава",
            },
            {
              value: "BASED_ON_THE_DECISION_OF_THE_FOUNDERS",
              label: "на основании решения учредителей",
            },
            {
              value: "ON_THE_BASIS_OF_AN_ORDER",
              label: "на основании приказа",
            },
          ]}
        />
      </ProForm.Group>
      <ProForm.Group></ProForm.Group>
      <ProForm.Group>
      {/* <ProFormText
          name="legalAddress"
          label="Юридический адрес"
          placeholder="Адрес"
          disabled={disabled}
        /> */}
        <ProFormText
          name="BIN"
          label="БИН"
          placeholder="БИН"
          rules={[requiredRule]}
          disabled={disabled}
        />
        <ProFormText
          name="accountNumber"
          label="Номер счета"
          placeholder="Номер счета"
          rules={[requiredRule]}
          disabled={disabled}
        />
        <ProFormText
          name="bankName"
          label="Наименование банка"
          placeholder="Наименование банка"
          rules={[requiredRule]}
          disabled={true}
        />
        <ProFormText
          name="BIC"
          label="БИК"
          placeholder="БИК"
          rules={[requiredRule]}
          disabled={true}
        />
      </ProForm.Group>
      <ProForm.Group></ProForm.Group>
    </>
  );
};

export default IndividualClientInfo;
